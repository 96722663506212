import { FeatureKeys } from "admin/components/features/feature.service";
import { CharTypeId } from "rl-common/consts";
import { Acl } from "rl-common/rl-common-acl.consts";
import { AclUtil } from "rl-common/utils/acl.util";

export class RouteSettings {
	public static mod2: RouteSetting = {
		acl: AclUtil.getDataEntityAcl(CharTypeId.User),
		charTypeId: CharTypeId.User,
		sideBarLink: "Contacts",
		path: "/mod2"
	};

	public static mod16: RouteSetting = {
		acl: AclUtil.getDataEntityAcl(CharTypeId.Project),
		charTypeId: CharTypeId.Project,
		sideBarLink: "Projects",
		path: "/mod16"
	};

	public static mod1: RouteSetting = {
		acl: AclUtil.getDataEntityAcl(CharTypeId.Property),
		charTypeId: CharTypeId.Property,
		sideBarLink: "Catalog",
		path: "/mod1"
	};

	public static mod4: RouteSetting = {
		acl: AclUtil.getDataEntityAcl(CharTypeId.Transaction),
		charTypeId: CharTypeId.Transaction,
		sideBarLink: "Deals",
		path: "/mod4"
	};

	public static mod17: RouteSetting = {
		acl: AclUtil.getDataEntityAcl(CharTypeId.Inventory),
		charTypeId: CharTypeId.Inventory,
		sideBarLink: "Inventory",
		path: "/mod17"
	};
	public static mod15: RouteSetting = {
		acl: AclUtil.getDataEntityAcl(CharTypeId.Job),
		charTypeId: CharTypeId.Job,
		sideBarLink: "Jobs",
		path: "/mod15"
	};

	public static mod7: RouteSetting = {
		acl: AclUtil.getDataEntityAcl(CharTypeId.Invoice),
		charTypeId: CharTypeId.Invoice,
		sideBarLink: "Accounting",
		path: "/mod7"
	};

	public static reporting: RouteSetting = {
		acl: Acl.Activities.ActivityAdminAcls.ReportsAndAvailsAcls.Reports.toString(),
		charTypeId: -1,
		sideBarLink: "Reporting",
		path: "/reporting"
	};

	public static available: RouteSetting = {
		acl: Acl.Activities.ActivityAdminAcls.ReportsAndAvailsAcls.Available.toString(),
		charTypeId: null,
		sideBarLink: "Available",
		path: null
	};


	public static comp3: RouteSetting = {
		acl: AclUtil.getDataEntityAcl(CharTypeId.Right),
		charTypeId: CharTypeId.Right,
		sideBarLink: "Rights",
		path: "/comp3",
		requiredFeatureFlag: FeatureKeys.ComponentListPages
	};

	public static comp5: RouteSetting = {
		acl: AclUtil.getDataEntityAcl(CharTypeId.Usage),
		charTypeId: CharTypeId.Usage,
		sideBarLink: "Tables",
		path: "/comp5",
		requiredFeatureFlag: FeatureKeys.ComponentListPages
	};

	public static comp10: RouteSetting = {
		acl: AclUtil.getDataEntityAcl(CharTypeId.Amount),
		charTypeId: CharTypeId.Amount,
		sideBarLink: "Amounts",
		path: "/comp10",
		requiredFeatureFlag: FeatureKeys.ComponentListPages
	};

	public static comp14: RouteSetting = {
		acl: AclUtil.getDataEntityAcl(CharTypeId.Document),
		charTypeId: CharTypeId.Document,
		sideBarLink: "Files",
		path: "/comp14",
		requiredFeatureFlag: FeatureKeys.ComponentListPages
	};

	public static components: RouteSetting = {
		acl: AclUtil.getDataEntityAcl(CharTypeId.Right),
		charTypeId: null,
		sideBarLink: "Components",
		path: null,
		requiredFeatureFlag: FeatureKeys.ComponentListPages,
		children: [
			RouteSettings.comp10,
			RouteSettings.comp14,
			RouteSettings.comp3,
			RouteSettings.comp5
		]
	};

}

export class RouteSetting {
	public acl: string;
	public charTypeId: number;
	public sideBarLink: string;
	public path: string;
	public requiredFeatureFlag?: FeatureKeys;
	public children?: RouteSetting[];
}

export const SideBarRoutes = [
	RouteSettings.mod2,
	RouteSettings.mod16,
	RouteSettings.mod1,
	RouteSettings.mod4,
	RouteSettings.mod17,
	RouteSettings.mod15,
	RouteSettings.mod7,
	RouteSettings.components,
	RouteSettings.reporting
];
