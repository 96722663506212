<div class="data-query">
	<div class="data-query__sidenav" [ngStyle]="{width : sidePanelWidthOpen + 'px', transform : translateXValue }">
		@if (savedCriteriaList.length === 0) {
		<div class="d-flex align-items-center save-criteria ps-3">
			<rl-text-input [placeholder]="'Save criteria as...'" [styles]="{classes: 'form-control-transparent'}"
				class="w-100" [(ngModel)]="name"></rl-text-input>
			<button class="btn btn-sm btn-link ms-2" (click)="saveCriteria()">Save</button>
		</div>
		} @else {
		<div class="d-flex flex-column justify-content-start save-criteria">
			<div ngbDropdown placement="bottom-left" [autoClose]="true" #saveCriteriaDropdown="ngbDropdown"
				class="position-relative dropdown">
				<button type="button"
					class="report-viewer__title-selection-menu title--overline dropdown-toggle position-relative px-3"
					ngbDropdownToggle data-cy="DropdownMenuButton" [title]="'My Saved Criteria'">My Saved
					Criteria</button>
				<div ngbDropdownMenu style="width:330px; padding:0;" data-cy="DropdownMenu">
					<div class="d-flex p-3 bg-light">
						<rl-text-input [placeholder]="'Save criteria as...'"
							[styles]="{classes: 'form-control-transparent'}" class="w-100"
							[(ngModel)]="name"></rl-text-input>
						<button class="btn btn-sm btn-link ms-2"
							(click)="saveCriteria()" *ngIf="!canResave()"> Save</button>
						<button class="btn btn-sm btn-link ms-2" (click)="updateSavedCriteria(selectedSavedCriteriaId)" *ngIf="canResave()">Resave</button>
					</div>
					<div class="list-group list-group-flush list-group--menu my-2">
						<!-- REPEATABLE ITEM : -->
						<div *ngFor="let savedCriteria of savedCriteriaList" class="list-group-item">
							<div class="list-group-item list-group-item-action active">
								<div class="d-flex align-items-center justify-content-start">
									<span class="list-group-item__check">
										<!-- Display if selected -->
										<i *ngIf="selectedSavedCriteriaId === savedCriteria.id"
											class="far fa-check text-success"></i>
										<!-- //end -->
									</span>
									<!-- clickable element: -->
									<a class="list-group-item__label w-auto ps-2 cursor-pointer text-grey"
										data-cy="Saved Item"
										(click)="reportCriteriaSelected(savedCriteria);saveCriteriaDropdown.close();">{{savedCriteria.reportName}}</a>
									<div class="list-group-item__actions ms-auto text-nowrap">
										<button data-cy="DeleteItem" class="btn btn-link btn-remove"
											(click)="deleteSavedCriteria(savedCriteria)">
											<i class="far fa-trash-alt"></i>
										</button>
									</div>
								</div>
							</div>
						</div>
						<!-- //end -->
					</div>
					<div class="border-top"></div>
					<button ngbDropdownItem class="dropdown-item p-2 my-2" (click)="deleteAllSavedCriteria()">
						<i class="far fa-trash-alt mx-2"></i>
						Delete All Saved Criteria
					</button>
				</div>
			</div>
			<div class="position-relative flex-fill ps-3">
				<h4>{{!selectedSavedCriteria ? "" : selectedSavedCriteria.reportName}}</h4>
			</div>
		</div>
		}
		<rl-collapsing-side-panel [isCollapsed]="isCollapsed" [openTo]="openTo" (sidePanelWidth)="updateWidth($event)"
			[sidePanelWidthOpen]="sidePanelWidthOpen" [sidePanelWidthClosed]="sidePanelWidthClosed">
			<div class="d-flex flex-column">

				<div class="data-query__sidenav-section top">
					<h5 class="data-query__sidenav-title mb-2 mt-3 mx-3 pb-2 border-bottom">
						Criteria
					</h5>

					<div class="position-relative overflow-auto">
						<ul class="data-query__sidenav-list list-unstyled m-2 mx-3">
							<li>
								<button [ngbPopover]="datesPopover" #dp="ngbPopover" [autoClose]="false"
									placement="right auto" class="data-query__sidenav-criteria-item"
									data-cy="datePopover" (click)="dp.close();rp.close();up.close();fp.close()"
									popoverClass="audit-history-report-popover-window-override">
									Dates<span class="text-danger ps-1">*</span>
								</button>
								<ng-template #datesPopover>
									<div class="d-flex justify-content-between align-items-center pb-1">
										<h5 class="pb-0">Dates</h5>
										<button class="btn btn-icon btn-sm" (click)="dp.close()" title="Close"
											data-cy="closeDatePopover">
											<i class="far fa-times"></i>
										</button>
									</div>
									<div class="param-popover-list scroll-vert p-2">
										<ul class="list-unstyled mb-0">
											<li class=" py-2" [ngClass]="{'text-danger has-error': errors?.date}">
												<label class="col-4 text-nowrap mt-1 me-2">Start Date:</label>
												<div>
													<rl-date-edit-control [dateValue]="criteriaRequest.startDate" [isEstimatedEnabled]="false"
														(dateValueChange)="updateDateValue($event, 'start')"
														[minDate]="minDate" [maxDate]="maxDate"></rl-date-edit-control>
												</div>
											</li>
											<li class=" py-2" [ngClass]="{'text-danger has-error': errors?.date}">
												<label class="col-4 text-nowrap mt-1 me-2">End Date:</label>
												<rl-date-edit-control [dateValue]="criteriaRequest.endDate" [isEstimatedEnabled]="false"
													(dateValueChange)="updateDateValue($event, 'end')"
													[minDate]="minDate" [maxDate]="maxDate"></rl-date-edit-control>
											</li>
											<li class=" py-2">
												<div class="d-flex justify-content-end pt-3">
													<button class="btn btn-sm btn-link" (click)="dp.close()"
														data-cy="doneDatePopover">Done</button>
												</div>
											</li>
											<li class="border-top pt-2">
												<em>Dates criteria and Audit Date in results are in UTC.</em>
											</li>
										</ul>
									</div>
								</ng-template>
							</li>
							<li>
								<button [ngbPopover]="recordsPopover" #rp="ngbPopover" [autoClose]="false"
									placement="right auto" class="data-query__sidenav-criteria-item"
									data-cy="recordsPopover" popoverClass="audit-history-report-popover-window-override"
									(click)="dp.close();rp.close();up.close();fp.close()">
									Records
								</button>
								<ng-template #recordsPopover>
									<div class="d-flex justify-content-between align-items-center pb-1">
										<h5 class="pb-0">Records</h5>
										<button class="btn btn-icon btn-sm" (click)="rp.close()" title="Close"
											data-cy="closeRecordsPopover">
											<i class="far fa-times"></i>
										</button>
									</div>
									<div class="param-popover-list scroll-vert border-top p-2">
										<ul class="list-unstyled mb-0">
											<li class="py-2">
												<label class="col-4 text-nowrap mt-1 me-2">Type:</label>
												<div>
													<select class="form-select" [(ngModel)]="selectedCharType"
														(change)="updateRecordValue('char')" data-cy="charTypeDropdown">
														<option *ngFor="let charType of charTypeIds"
															[ngValue]="charType">
															{{ charType | charTypeName }}
														</option>
													</select>
												</div>
											</li>
											<li class="py-2">
												<label class="col-4 text-nowrap mt-1 me-2">Template:</label>
												<select class="form-select" [(ngModel)]="selectedTemplate"
													(change)="updateRecordValue('template')" data-cy="templateDropdown">
													<option selected [ngValue]="null">All Templates</option>
													<option *ngFor="let template of templates" [ngValue]="template">
														{{ template.templateName }}
													</option>
												</select>
											</li>
											<li class="py-2">
												<button class="btn btn-link btn-sm" data-cy="addRecordsButton"
													(click)="updateRecordValue('record')"
													[disabled]="selectedCharType === -1">+ Add Records</button>
											</li>
											<li>
												<rl-chip *ngFor="let recordId of criteriaRequest.recordIds"
													(onRemove)="removeChip(recordId,'selectedRecordIds')">
													{{getRecordName(recordId)}}
												</rl-chip>
											</li>
											<li class=" py-2">
												<div class="d-flex justify-content-end pt-3">
													<button class="btn btn-sm btn-link" (click)="rp.close()"
														data-cy="doneRecordsPopover">Done</button>
												</div>
											</li>
											<li class="border-top pt-2">
												<em>Selections here will affect the options under Fields, Workflow, and
													Association.</em>
											</li>
										</ul>
									</div>
								</ng-template>
							</li>
							<li>
								<button [ngbPopover]="usersPopover" #up="ngbPopover" [autoClose]="false"
									placement="right auto" class="data-query__sidenav-criteria-item"
									data-cy="usersPopover" (click)="dp.close();rp.close();up.close();fp.close()"
									popoverClass="audit-history-report-popover-window-override">
									Users
								</button>
								<ng-template #usersPopover>
									<div class="d-flex justify-content-between align-items-center pb-1">
										<h5 class="pb-0">Users</h5>
										<button class="btn btn-icon btn-sm" (click)="up.close()" title="Close"
											data-cy="closeUsersPopover">
											<i class="far fa-times"></i>
										</button>
									</div>
									<div class="param-popover-list scroll-vert border-top p-2">
										<ul class="list-unstyled mb-0">
											<li class=" py-2">
												<button class="btn btn-link btn-sm" data-cy="addUsersButton"
													(click)="updateRecordValue('user')">+ Add
													Users</button>
											</li>
											<li>
												<rl-chip *ngFor="let userId of criteriaRequest.userIds"
													(onRemove)="removeChip(userId,'selectedUsers')">
													{{getUserName(userId)}}
												</rl-chip>
											</li>
											<li class=" py-2">
												<div class="d-flex justify-content-end pt-3">
													<button class="btn btn-sm btn-link" (click)="up.close()"
														data-cy="doneUsersPopover">Done</button>
												</div>
											</li>
										</ul>
									</div>
								</ng-template>
							</li>
							<li>
								<button [ngbPopover]="fieldPopover" #fp="ngbPopover" [autoClose]="false"
									placement="right auto" class="data-query__sidenav-criteria-item"
									data-cy="fieldPopover" (click)="dp.close();rp.close();up.close();fp.close()"
									popoverClass="audit-history-report-popover-window-override">
									Fields<span class="text-warning-dark ps-1">*</span>
								</button>
								<ng-template #fieldPopover>
									<div class="d-flex justify-content-between align-items-center pb-1">
										<h5 class="pb-0">Fields</h5>
										<button class="btn btn-icon btn-sm" (click)="fp.close()" title="Close"
											data-cy="closeFieldsPopover">
											<i class="far fa-times"></i>
										</button>
									</div>
									<div class="param-popover-list scroll-vert border-top p-2">
										<ul class="list-unstyled mb-0">
											<li class="py-2">
												<h6 class="text-nowrap mt-1 mb-2 me-2">Show Values</h6>
												<div>
													<rl-checkbox-input type="checkbox"
														[(ngModel)]="criteriaRequest.fieldValueAdded"
														(onChange)="updateFieldValue($event,'fieldValueAdded')"
														[dataLabel]="'FieldAddedCheckbox'"></rl-checkbox-input>
													Added
												</div>
												<div>
													<rl-checkbox-input type="checkbox"
														[(ngModel)]="criteriaRequest.fieldValueUpdated"
														(onChange)="updateFieldValue($event,'fieldValueUpdated')"
														[dataLabel]="'FieldUpdatedCheckbox'"></rl-checkbox-input>
													Updated
												</div>
												<div>
													<rl-checkbox-input type="checkbox"
														[(ngModel)]="criteriaRequest.fieldValueDeleted"
														(onChange)="updateFieldValue($event,'fieldValueDeleted')"
														[dataLabel]="'FieldDeletedCheckbox'"></rl-checkbox-input>
													Deleted
												</div>
											</li>
											<li class="py-2">
												<button class="btn btn-link btn-sm" data-cy="addFieldsButton"
													(click)="characteristicSearch()"
													[disabled]="selectedCharType === -1">+ Add Fields</button>
											</li>
											<li class="py-2" *ngIf="criteriaRequest.fieldIds.length > 0">
												<rl-chip *ngFor="let fieldId of criteriaRequest.fieldIds"
													(onRemove)="removeChip(fieldId,'selectedFields')">
													{{getFieldName(fieldId)}}
												</rl-chip>
											</li>
											<li class="py-2">
												<div class="d-flex justify-content-end pt-3">
													<button class="btn btn-sm btn-link" (click)="fp.close()"
														data-cy="doneFieldsPopover">Done</button>
												</div>
											</li>
											<li class="border-top pt-2">
												<em>Select a Type under Records to Add Fields here.</em><br>
												<span class="text-warning-dark pe-1">*</span><em>To return results, at
													least one selection must be made on Fields, Workflow, or
													Association.</em>
											</li>
										</ul>
									</div>
								</ng-template>
							</li>
							<li>
								<button [ngbPopover]="workflowPopover" #wp="ngbPopover" [autoClose]="'outside'"
									placement="right auto" data-trigger="focus"
									class="data-query__sidenav-criteria-item" data-cy="workflowPopover"
									(click)="dp.close();rp.close();up.close();fp.close()"
									popoverClass="audit-history-report-popover-window-override">
									Workflow<span class="text-warning-dark ps-1">*</span>
								</button>
								<ng-template #workflowPopover>
									<div class="d-flex justify-content-between align-items-center pb-1">
										<h5 class="pb-0">Workflow</h5>
										<button class="btn btn-icon btn-sm" (click)="wp.close()" title="Close"
											data-cy="WorkflowPopoverClose">
											<i class="far fa-times"></i>
										</button>
									</div>
									<div class="param-popover-list scroll-vert border-top p-2">
										<ul class="list-unstyled mb-0">
											<li class="py-2">
												<h6 class="text-nowrap mt-1 mb-2 me-2">Show Records</h6>
												<div>
													<rl-checkbox-input type="checkbox"
														[(ngModel)]="criteriaRequest.workflowCreatedState"
														(onChange)="updateFieldValue($event,'workflowCreatedState')"
														[dataLabel]="'WorkflowCreatedCheckbox'"></rl-checkbox-input>
													Created
												</div>
												<div>
													<rl-checkbox-input type="checkbox"
														[(ngModel)]="criteriaRequest.workflowDeletedState"
														(onChange)="updateFieldValue($event,'workflowDeletedState')"
														[dataLabel]="'WorkflowDeletedCheckbox'"></rl-checkbox-input>
													Deleted
												</div>
											</li>
											<li class=" py-2 form-check">
												<input type="radio" name="workflowSelection"
													[(ngModel)]="criteriaRequest.workflowSelection" value="1"
													class="form-check-input" (change)="resetStoredStatusAction()"
													data-cy="WorkflowAllStatusesActions">
												<label>All Statuses & Actions</label>
											</li>
											<li class=" py-2 form-check">
												<input type="radio" name="workflowSelection"
													[(ngModel)]="criteriaRequest.workflowSelection" value="2"
													class="form-check-input" (change)="resetStoredStatusAction()"
													[disabled]="!workflowOptionsReady" data-cy="WorkflowStatusChanged">
												<label>Status changed from</label>
												<div class="mb-2">
													<select class="form-select"
														[(ngModel)]="criteriaRequest.workflowFromStepId"
														[disabled]="!workflowOptionsReady || criteriaRequest.workflowSelection != 2"
														(ngModelChange)="onCriteriaUpdated()"
														data-cy="WorkflowStatusFrom">
														<option *ngFor="let status of availableWorkflowStatuses"
															[ngValue]="status.stepId">
															{{ status.stepName }}
														</option>
													</select>
												</div>
												<label>Status changed to</label>
												<div>
													<select class="form-select"
														[(ngModel)]="criteriaRequest.workflowToStepId"
														[disabled]="!workflowOptionsReady || criteriaRequest.workflowSelection != 2"
														(ngModelChange)="onCriteriaUpdated()"
														data-cy="WorkflowStatusTo">
														<option *ngFor="let status of availableWorkflowStatuses"
															[ngValue]="status.stepId">
															{{ status.stepName }}
														</option>
													</select>
												</div>
											</li>
											<li class="py-2 form-check">
												<input type="radio" name="workflowSelection"
													[(ngModel)]="criteriaRequest.workflowSelection" value="3"
													class="form-check-input" (change)="resetStoredStatusAction()"
													[disabled]="!workflowOptionsReady" data-cy="WorkflowActionTaken">
												<label>Action taken</label>
												<div>
													<select class="form-select"
														[(ngModel)]="criteriaRequest.workflowActionId"
														[disabled]="!workflowOptionsReady || criteriaRequest.workflowSelection != 3"
														(ngModelChange)="onCriteriaUpdated()" data-cy="WorkflowAction">
														<option *ngFor="let action of availableWorkflowActions"
															[ngValue]="action.actionId">
															{{ action.actionName }}
														</option>
													</select>
												</div>
											</li>
											<li class="d-flex justify-content-end">
												<button class="btn btn-sm btn-link" (click)="resetAllWorkflow()"
													data-cy="WorkflowClearAll">Clear</button>
												<button class="btn btn-sm btn-link" (click)="wp.close()"
													data-cy="doneFieldsPopover">Done</button>

											</li>
											<li class="border-top pt-2">
												<em>Select a Template under Records to select from Status or Action
													options here.</em><br>
												<span class="text-warning-dark pe-1">*</span><em>To return results, at
													least one selection must be made on Fields, Workflow, or
													Association.</em>
											</li>
										</ul>
									</div>
								</ng-template>
							</li>
							<li>
								<button [ngbPopover]="associationPopover" #ap="ngbPopover" [autoClose]="'outside'"
									placement="right auto" class="data-query__sidenav-criteria-item"
									data-cy="associationPopover" (click)="dp.close();rp.close();up.close();fp.close()"
									popoverClass="audit-history-report-popover-window-override">
									Association<span class="text-warning-dark ps-1">*</span>
								</button>
								<ng-template #associationPopover>
									<div class="d-flex justify-content-between align-items-center pb-1">
										<h5 class="pb-0">Association</h5>
										<button class="btn btn-icon btn-sm" (click)="ap.close()" title="Close"
											data-cy="AssociationPopoverClose">
											<i class="far fa-times"></i>
										</button>
									</div>
									<div class="param-popover-list scroll-vert border-top p-2">
										<ul class="list-unstyled mb-0">
											<li>
												<h6 class="text-nowrap mt-1 mb-2 me-2">Show association to</h6>
											</li>
											<li class="py-2">
												<label class="col-4 text-nowrap mt-1 me-2">{{criteriaRequest.charTypeId
													> -1 ? (criteriaRequest.charTypeId
													| charTypeName) + ' ' : ''}}Child:</label>
												<div>
													<select [(ngModel)]="criteriaRequest.childCharTypeId"
														(ngModelChange)="onCriteriaUpdated()" class="form-select"
														data-cy="AssociationChildChar">
														<option selected [ngValue]="-1">All Types</option>
														<option *ngFor="let child of validChildren"
															[ngValue]="child.charTypeID"
															[hidden]="criteriaRequest.charTypeId < 0 && child.charTypeID > -1">
															{{ child.charTypeName }}
														</option>
													</select>
												</div>
												<div class="pt-1">
													<rl-checkbox-input
														[ngModel]="criteriaRequest.associationToChildCreated"
														(onChange)="updateFieldValue($event,'associationToChildCreated')"
														[dataLabel]="'AssociationChildUpdate'"></rl-checkbox-input>
													Created
												</div>
												<div class="pb-2">
													<rl-checkbox-input
														[ngModel]="criteriaRequest.associationToChildDeleted"
														(onChange)="updateFieldValue($event,'associationToChildDeleted')"
														[dataLabel]="'AssociationChildDeleted'"></rl-checkbox-input>
													Deleted
												</div>
											</li>
											<li class="py-2">
												<label class="col-4 text-nowrap mt-1 me-2">{{criteriaRequest.charTypeId
													> -1 ? (criteriaRequest.charTypeId
													| charTypeName) + ' ' : ''}}Parent:</label>
												<select [(ngModel)]="criteriaRequest.parentCharTypeId"
													(ngModelChange)="onCriteriaUpdated()" class="form-select"
													data-cy="AssociationParentChar">
													<option selected [ngValue]="-1">All Types</option>
													<option *ngFor="let parent of validParents"
														[ngValue]="parent.charTypeID"
														[hidden]="criteriaRequest.charTypeId < 0 && parent.charTypeID > -1">
														{{ parent.charTypeName }}
													</option>
												</select>
												<div class="pt-1">
													<rl-checkbox-input
														[ngModel]="criteriaRequest.associationToParentCreated"
														(onChange)="updateFieldValue($event,'associationToParentCreated')"
														[dataLabel]="'AssociationParentCreated'"></rl-checkbox-input>
													Created
												</div>
												<div class="pb-2">
													<rl-checkbox-input
														[ngModel]="criteriaRequest.associationToParentDeleted"
														(onChange)="updateFieldValue($event,'associationToParentDeleted')"
														[dataLabel]="'AssociationParentDeleted'"></rl-checkbox-input>
													Deleted
												</div>
											</li>
											<li class="border-top pt-2">
												<em>Select a Type under Records to populate Child and Parent
													types.</em><br>
												<span class="text-warning-dark pe-1">*</span><em>To return results, at
													least one selection must be made on Fields, Workflow, or
													Association.</em>
											</li>
										</ul>
									</div>
								</ng-template>
							</li>
						</ul>
					</div>
				</div>

				<div class="data-query__sidenav-section bottom">
					<div class="d-flex justify-content-between align-items-center mb-2 mt-3 mx-3 pb-2 border-bottom">
						<h5 class="data-query__sidenav-title mb-0">
							Selected Criteria
						</h5>
						<button class="btn btn-link btn-sm" (click)="resetAll()">Reset All</button>
					</div>

					<div class="position-relative overflow-auto">
						<ul class="data-query__sidenav-list list-unstyled m-2 mx-3" *ngIf="criteriaRequest">
							<li class="d-flex align-items-start">
								<a class="btn btn-sm btn-icon btn-remove h-auto px-1 me-0" title="Remove Criteria"
									role="button" (click)="removeSelectedCriteriaItem('StartEndDate')"
									data-cy="removeSelectedDates"><i class="far fa-sm fa-undo-alt"></i></a>
								<div>
									<strong class="pe-1">Dates:</strong>
									{{formatByFieldType('StartEndDate', [criteriaRequest.startDate,
									criteriaRequest.endDate])}}
								</div>
							</li>
							<li *ngIf="criteriaRequest.charTypeId > -1" class="d-flex align-items-start">
								<a class="btn btn-sm btn-icon btn-remove h-auto px-1 me-1" title="Remove Criteria"
									role="button" (click)="removeSelectedCriteriaItem('Records')"
									data-cy="removeSelectedRecords"><i class="fas fa-times fa-sm"></i></a>
								<div>
									<strong class="pe-1">Records:</strong>
									{{formatByFieldType('Records',
									[criteriaRequest.charTypeId, criteriaRequest.templateId])}}
								</div>
							</li>
							<li *ngIf="criteriaRequest.recordIds.length > 0" class="d-flex align-items-start">
								<a class="btn btn-sm btn-icon btn-remove h-auto px-1 me-1" title="Remove Criteria"
									role="button" (click)="removeSelectedCriteriaItem('selectedRecords')"
									data-cy="removeSelectedRecordItems"><i class="fas fa-times fa-sm"></i></a>
								<div>
									<strong class="pe-1 text-nowrap">Selected Records:</strong>
									<span
										*ngFor="let recordId of criteriaRequest.recordIds; let last = last;">{{getRecordName(recordId)}}<span
											*ngIf="!last">, </span>
									</span>
								</div>
							</li>
							<li *ngIf="criteriaRequest.userIds.length > 0" class="d-flex align-items-start">
								<a class="btn btn-sm btn-icon btn-remove h-auto px-1 me-1" title="Remove Criteria"
									role="button" (click)="removeSelectedCriteriaItem('selectedUsers')"
									data-cy="removeSelectedUsers"><i class="fas fa-times fa-sm"></i></a>
								<div>
									<strong class="pe-1 text-nowrap">Selected Users:</strong>
									<span
										*ngFor="let userId of criteriaRequest.userIds; let last = last;">{{getUserName(userId)}}<span
											*ngIf="!last">, </span>
									</span>
								</div>
							</li>
							<li class="d-flex align-items-start"
								*ngIf="!!criteriaRequest.fieldValueAdded || !!criteriaRequest.fieldValueUpdated || !!criteriaRequest.fieldValueDeleted">
								<a class="btn btn-sm btn-icon btn-remove h-auto px-1 me-1" title="Remove Criteria"
									role="button" (click)="removeSelectedCriteriaItem('field')"
									data-cy="removeSelectedFields"><i class="fas fa-times fa-sm"></i></a>
								<div>
									<strong class="pe-1 text-nowrap">Fields:</strong>
									<span *ngIf="!!criteriaRequest.fieldValueAdded">Show
										Added{{!!criteriaRequest.fieldValueUpdated
										|| !!criteriaRequest.fieldValueDeleted ? ', ' : ' '}}</span>
									<span *ngIf="!!criteriaRequest.fieldValueUpdated">Show
										Updated{{!!criteriaRequest.fieldValueDeleted ? ', ' : ' '}}</span>
									<span *ngIf="!!criteriaRequest.fieldValueDeleted">Show
										Deleted</span>
								</div>
							</li>
							<li *ngIf="criteriaRequest.fieldIds.length > 0 && selectedFields.length > 0"
								class="d-flex align-items-start">
								<a class="btn btn-sm btn-icon btn-remove h-auto px-1 me-1" title="Remove Criteria"
									role="button" (click)="removeSelectedCriteriaItem('selectedFields')"
									data-cy="removeSelectedFieldItems"><i class="fas fa-times fa-sm"></i></a>
								<div>
									<strong class="pe-1 text-nowrap">Selected Fields:</strong>
									<span
										*ngFor="let fieldId of criteriaRequest.fieldIds; let last = last;">{{getFieldName(fieldId)}}<span
											*ngIf="!last">, </span>
									</span>
								</div>
							</li>
							<li class="d-flex align-items-start"
								*ngIf="!!criteriaRequest.workflowSelection || !!criteriaRequest.workflowCreatedState || !!criteriaRequest.workflowDeletedState">
								<a class="btn btn-sm btn-icon btn-remove h-auto px-1 me-1" title="Remove Criteria"
									role="button" (click)="removeSelectedCriteriaItem('workflow')"
									data-cy="removeSelectedWorkflow"><i class="fas fa-times fa-sm"></i></a>
								<div>
									<strong class="pe-1 text-nowrap">Workflow:</strong>
									<span *ngIf="!!criteriaRequest.workflowCreatedState">Show
										Created{{!!criteriaRequest.workflowDeletedState
										|| !!criteriaRequest.workflowSelection ? ', ' : ' '}}</span>
									<span *ngIf="!!criteriaRequest.workflowDeletedState">Show
										Deleted{{!!criteriaRequest.workflowSelection ?
										', ' : ' '}}</span>
									<span *ngIf="criteriaRequest.workflowSelection == 1">Show All Statuses & Actions
									</span>
									<span
										*ngIf="criteriaRequest.workflowSelection == 2 && (!!criteriaRequest.workflowFromStepId || !!criteriaRequest.workflowToStepId)">Show
										Status changed
										<span *ngIf="!!criteriaRequest.workflowFromStepId">
											from <em>{{getStepName(criteriaRequest.workflowFromStepId)}}</em>
										</span>
										<span *ngIf="!!criteriaRequest.workflowToStepId">
											to <em>{{getStepName(criteriaRequest.workflowToStepId)}}</em>
										</span>
									</span>
									<span
										*ngIf="criteriaRequest.workflowSelection == 3 && !!criteriaRequest.workflowActionId">Show
										Action taken <em>{{getActionName(criteriaRequest.workflowActionId)}}</em></span>
								</div>
							</li>
							<li class="d-flex align-items-start" *ngIf="criteriaRequest.childCharTypeId > -1 || !!criteriaRequest.associationToChildCreated || !!criteriaRequest.associationToChildDeleted || criteriaRequest.parentCharTypeId > -1 || !!criteriaRequest.associationToParentCreated ||
								!!criteriaRequest.associationToParentDeleted">
								<a class="btn btn-sm btn-icon btn-remove h-auto px-1 me-1" title="Remove Criteria"
									role="button" (click)="removeSelectedCriteriaItem('association')"
									data-cy="removeSelectedAssociation"><i class="fas fa-times fa-sm"></i></a>
								<div>
									<strong class="pe-1 text-nowrap">Association:</strong>
									<!-- Child: Inventory, Show Created, Show Deleted -->
									<span
										*ngIf="criteriaRequest.childCharTypeId > -1 || !!criteriaRequest.associationToChildCreated || !!criteriaRequest.associationToChildDeleted">Child:
										{{criteriaRequest.childCharTypeId > -1 ? (criteriaRequest.childCharTypeId |
										charTypeName) : 'All Types'}}{{!!criteriaRequest.associationToChildCreated ||
										!!criteriaRequest.associationToChildDeleted ? ', ' : ' '}}
										<span *ngIf="!!criteriaRequest.associationToChildCreated">Show
											Created{{!!criteriaRequest.associationToChildDeleted ? ', ' : ' '}}</span>
										<span *ngIf="!!criteriaRequest.associationToChildDeleted">Show
											Deleted</span>
									</span>
									<span
										*ngIf="criteriaRequest.parentCharTypeId > -1 || !!criteriaRequest.associationToParentCreated || !!criteriaRequest.associationToParentDeleted">{{criteriaRequest.childCharTypeId
										> -1 || !!criteriaRequest.associationToChildCreated ||
										!!criteriaRequest.associationToChildDeleted ? '; ' : ''}}Parent:
										{{criteriaRequest.parentCharTypeId > -1 ? (criteriaRequest.parentCharTypeId |
										charTypeName) : 'All Types'}}{{!!criteriaRequest.associationToParentCreated ||
										!!criteriaRequest.associationToParentDeleted ? ', ' : ' '}}
										<span *ngIf="!!criteriaRequest.associationToParentCreated">Show
											Created{{!!criteriaRequest.associationToParentDeleted ? ', ' : ' '}}</span>
										<span *ngIf="!!criteriaRequest.associationToParentDeleted">Show
											Deleted</span>
									</span>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</rl-collapsing-side-panel>

	</div>
	<div class="data-query__report" [ngStyle]="{'margin-left.px' : sidePanelWidth}">
		<div class="report-viewer d-block position-relative h-100 bg-white">
			<div class="report-viewer__head d-flex align-items-center justify-content-between">
				<div class="report-viewer__title d-flex flex-column justify-content-start">
					<span class="title--overline ps-3 pt-1">
						DATA NAVIGATOR
					</span>
					<div class="position-relative flex-fill ps-3">
						<h4>Audit History</h4>
					</div>
				</div>
				<button class="btn btn-icon btn-info btn-sm ms-auto me-2 cursor-pointer" data-pendo="audit-history-run"
					role="button" aria-label="button">
					<i class="far fa-question-circle"></i>
				</button>
				<button class="btn btn-success btn-sm me-3" (click)="runReport()" [disabled]="!criteriaRequest.fieldValueAdded &&
					!criteriaRequest.fieldValueUpdated && !criteriaRequest.fieldValueDeleted &&
					criteriaRequest.fieldIds.length === 0 && selectedFields.length === 0 &&
					!criteriaRequest.workflowSelection && !criteriaRequest.workflowCreatedState && !criteriaRequest.workflowDeletedState &&
					criteriaRequest.childCharTypeId === -1 && !criteriaRequest.associationToChildCreated &&
					!criteriaRequest.associationToChildDeleted && criteriaRequest.parentCharTypeId === -1 &&
					!criteriaRequest.associationToParentCreated && !criteriaRequest.associationToParentDeleted">
					Run
				</button>
			</div>
			@if (!showResults) {
			<!-- intro screen -->
			<h1 class="report-view__intro-title my-5 pt-3 text-center">Get Started with Data Navigator</h1>
			<div class="d-flex justify-content-center">
				<div class="px-4 text-center">
					<img src="/assets/img/audit-history-intro1.png" border="0" class="pb-2" />
					<h4 class="report-view__intro-sub-title py-2">Select your Criteria</h4>
					<p>Build your search criteria by clicking an item in the<br>side panel and configuring its settings.
					</p>
					<p>You can save your criteria by naming it at the top<br>of the Criteria panel.</p>
				</div>
				<div class="px-4 text-center">
					<img src="/assets/img/audit-history-intro2.png" border="0" class="pb-2" />
					<h4 class="report-view__intro-sub-title py-2">View your Results</h4>
					<p>Once all of your criteria is selected, click<br>Run at the top of the Results panel.</p>
				</div>
			</div>
			} @else {

			<!-- reporting data -->
			<div class="p-2">
				<div class="card-body">
					<div class="text-end">
						<div ngbDropdown placement="bottom-right" class="d-inline-block">
							<button type="button" class="btn btn-icon btn-info ms-1 hide-caret" ngbDropdownToggle
								role="button" aria-label="Expand Menu" data-cy="AuditHistoryExpandMenu">
								<i class="far fa-bars"></i>
							</button>
							<div ngbDropdownMenu>
								<button ngbDropdownItem (click)="exportAuditHistory('csv')"
									data-cy="AuditHistoryButtonExportCsv">
									Export CSV
								</button>
								<button ngbDropdownItem (click)="exportAuditHistory('xlsx')"
									data-cy="AuditHistoryButtonExportXlsx">
									Export Excel
								</button>
							</div>
						</div>
					</div>
				</div>
				<rl-grid [dataSource]="dataSource"></rl-grid>
			</div>
			}
		</div>
	</div>
</div>