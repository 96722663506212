import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { FeatureKeys, FeatureService } from "./../../+admin/components/features/feature.service";

@Injectable({
	providedIn: "root"
})
export class FeatureFlagGuard implements CanActivate {
	constructor(
		private readonly _featureService: FeatureService,
		private readonly _router: Router
	) {

	}
	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

		const requiredFeatureFlag: FeatureKeys = route.data[
			"requiredFeatureFlag"
		] as FeatureKeys;
		const featureFlagRedirect: string =
			(route.data["featureFlagRedirect"] as string) || "/";
		return this._featureService.isEnabled$(requiredFeatureFlag)
			.pipe(
				map(isEnabled => {
					return isEnabled ? true : this._router.createUrlTree([featureFlagRedirect]);
				})
			);
	}
}
