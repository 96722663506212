import { Injectable } from "@angular/core";
import moment from "moment";
import { RlHttpClient } from "rl-common/services/rl-http-client.service";
import { IAuditHistoryNavigatorCriteriaRequest, IAuditHistoryNavigatorResponse } from "./audit-history-navigator.models";

type AuditHistoryNavigatorQueryParams = {
	[prop in keyof IAuditHistoryNavigatorCriteriaRequest]: string
};

@Injectable({ providedIn: "root" })
export class AuditHistoryNavigatorService {

	constructor(
		private readonly _httpClient: RlHttpClient
	) {
	}

	runAuditHistory(request) {
		return this._httpClient
			.post(`/entity-audit-history/audit-history-navigator`)
			.body(request)
			.fetch<IAuditHistoryNavigatorResponse[]>();
	}

	runExport(request) {
		return this._httpClient
			.post(`/entity-audit-history/audit-history-navigator-export`)
			.body(request)
			.fetch<number>();
	}

	runSelectedExport(request) {
		return this._httpClient
			.post(`/entity-audit-history/audit-history-navigator-selected`)
			.body(request)
			.fetch<number>();
	}

	getDefaultCriteriaRequest(): IAuditHistoryNavigatorCriteriaRequest {

		const currentMonth = moment().startOf("month");
		const today = moment().startOf("day");
		const startDate = currentMonth.subtract(3, "month").format("L");
		const endDate = today.format("L");

		return {
			startDate,
			endDate,
			extendSearch: false,
			charTypeId: -1,
			templateId: null,
			recordIds: [],
			userIds: [],
			fieldIds: [],
			fieldValueAdded: false,
			fieldValueUpdated: false,
			fieldValueDeleted: false,
			workflowSelection: null,
			workflowCreatedState: false,
			workflowDeletedState: false,
			workflowFromStepId: null,
			workflowToStepId: null,
			workflowActionId: null,
			associationToChildCreated: false,
			associationToChildDeleted: false,
			associationToParentCreated: false,
			associationToParentDeleted: false,
			childCharTypeId: -1,
			parentCharTypeId: -1,
			allWorkflowStatus: false,
			rowsPerPage: 25,
			pageOffset: 1,
		};
	}

}
